<template>
  <div v-if="visible" class="banner-notifications">
    <s-alert
      v-for="(notification, index) of notifications"
      :key="notification.bannerNotificationSettingDisplayId"
      :id="$generateId('banner-notification-' + index)"
      class="mb-24"
      closeAble
      @close="handleCloseNotification(notification)"
    >
      <h4 v-if="notification.bannerTitle">{{ notification.bannerTitle }}</h4>
      <div
        class="banner-notification__content"
        v-if="notification.content"
        v-html="$utils.sanitizeHtml(notification.content)"
      ></div>
    </s-alert>
  </div>
</template>
<script>
export default {
  data() {
    return {
      notifications: [],
      pathsOfNotificationDisplay: [],
    }
  },
  computed: {
    visible() {
      return (
        this.notifications.length &&
        this.pathsOfNotificationDisplay.some((path) =>
          this.$route.fullPath.match(path)
        )
      )
    },
  },
  created() {
    this.initNotifications()
    this.initPathsOfNotificationDisplay()
  },
  methods: {
    initNotifications() {
      let notifications = this.$store.state.userInfo?.bannerNotificationList
      if (Array.isArray(notifications)) {
        notifications = notifications.filter(
          (notification) => notification.isActive
        )
      }
      this.notifications = notifications
    },
    initPathsOfNotificationDisplay() {
      const paths = []
      const userInfo = this.$store.state.userInfo
      if (userInfo.profile.roleType === 'CUSTOMER') {
        // application id length is 18 normally
        paths.push('/service/my-line', /\/application\/\d{1,24}/i)
      }

      const menuList = userInfo?.roleConfiguration?.menuList || []
      let topMenus = menuList.filter((item) => {
        return item.type === 'topMenu' && item.path
      })
      topMenus = this.$lodash.orderBy(topMenus, ['displayOrder'], ['asc'])
      const defaultPath = userInfo?.roleConfiguration?.defaultPagePath || ''
      const isTopMenusIncludesDefaultPath = topMenus.some((item) => {
        return item.path === defaultPath
      })
      if (isTopMenusIncludesDefaultPath) {
        paths.push(defaultPath)
      } else {
        paths.push(topMenus[0].path)
      }
      this.pathsOfNotificationDisplay = paths
    },
    handleCloseNotification({ bannerNotificationSettingDisplayId }) {
      this.removeNotification(bannerNotificationSettingDisplayId)
      this.removeNotificationInStore(bannerNotificationSettingDisplayId)
    },
    removeNotification(notificationId) {
      if (Array.isArray(this.notifications)) {
        const notificationIndex = this.notifications.findIndex(
          (notification) =>
            notification.bannerNotificationSettingDisplayId === notificationId
        )
        if (notificationIndex !== -1) {
          this.notifications.splice(notificationIndex, 1)
        }
      }
    },
    removeNotificationInStore(notificationId) {
      const userInfo = this.$store.state.userInfo
      const notifications = userInfo?.bannerNotificationList
      if (Array.isArray(notifications)) {
        const notificationIndex = notifications.findIndex(
          (notification) =>
            notification.bannerNotificationSettingDisplayId === notificationId
        )
        if (notificationIndex !== -1) {
          notifications.splice(notificationIndex, 1)
          this.$store.commit('setUserInfo', userInfo)
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
:deep(.banner-notification__content) {
  :first-child {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
  :last-child {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}
</style>
