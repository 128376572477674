<template>
  <div class="global-search-history" v-if="!$utils.isEmpty(searchHistory)">
    <div class="theme-text-400 fs-14 mb-16">
      {{ $t('search_recent') }}
    </div>
    <div
      class="search-history-item"
      v-for="history in searchHistory"
      :key="history"
    >
      <s-button
        button-type="text"
        size="sm"
        class="px-0"
        @click.native="handleSelectHistory(history)"
      >
        {{ $utils.format.text(history) }}
      </s-button>
      <s-button
        button-type="primary"
        icon-only
        icon="cancel"
        size="sm"
        :aria-label="$t('search_delete_history')"
        @click.native="handleDeleteHistory(history)"
      ></s-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'search-history',
  computed: {
    searchHistory() {
      return this.$store.state.SEARCH_HISTORY
    },
  },
  methods: {
    handleSelectHistory(history) {
      this.$emit('selectHistory', history)
    },
    handleDeleteHistory(history) {
      this.$store.commit('deleteSearchHistory', history)
    },
  },
}
</script>

<style lang="scss" scoped>
.global-search-history {
  margin-top: 10px;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  max-height: 50vh;
  overflow-y: auto;
  .search-history-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
