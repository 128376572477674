<template>
  <div class="search-result-container">
    <s-loading :loading="loading">
      <div class="search-result-group" v-if="!$utils.isEmpty(resultGroups)">
        <template v-for="group in resultGroups">
          <div
            class="search-result-group-item"
            v-if="!$utils.isEmpty(group.results)"
            :class="[{ 'pb-8': group.collapse }]"
            :key="group.groupName"
          >
            <div class="group-title">
              {{ $utils.format.text(group.groupName) }}
              <s-button
                icon="arrow-bottom"
                icon-only
                class="group-toggle-button"
                :class="[{ 'rotate-90': group.collapse }]"
                size="sm"
                @click.native="handleToggleCollapse(group)"
              ></s-button>
            </div>
            <div class="result-list" v-if="!group.collapse">
              <div
                class="result-item"
                v-for="(result, index) in group.results"
                :key="`result-${index}`"
              >
                <a
                  href="javascript:void(0)"
                  @click="linkToResultPage(result.resultRouter)"
                >
                  <div
                    class="d-flex justify-content-between"
                    v-if="!$utils.isEmpty(result.resultTitle)"
                  >
                    <text-highlight
                      class="result-title"
                      :queries="splitSearchKey"
                    >
                      {{ $utils.format.text(result.resultTitle) }}
                    </text-highlight>
                    <span class="result-status" v-if="result.status">
                      {{ $utils.format.text(result.status) }}
                    </span>
                  </div>
                  <div
                    class="assets-list"
                    v-if="!$utils.isEmpty(renderAssets(result))"
                  >
                    <s-tag
                      class="assets-number"
                      size="mini"
                      type="normal"
                      v-for="(assetNumber, index) in renderAssets(result)"
                      :key="`assetNumber-${index}`"
                    >
                      <text-highlight :queries="splitSearchKey">
                        {{ $utils.format.text(assetNumber) }}
                      </text-highlight>
                    </s-tag>
                    <s-button
                      button-type="outline-primary"
                      size="sm"
                      class="toggle-assets-btn"
                      v-if="!$utils.isNormalEmpty(result.showMoreAssets)"
                      @click.native="handleToggleMoreAssets($event, result)"
                    >
                      {{ toggleMoreAssetsText(result) }}
                    </s-button>
                  </div>
                  <div
                    v-for="(content, index) in renderContents(result)"
                    :key="`content-${index}`"
                  >
                    <s-button
                      v-if="!$utils.isEmpty(content.contentRouter)"
                      button-type="text"
                      class="result-contents"
                      @click.native="
                        linkToContentPage($event, content.contentRouter)
                      "
                    >
                      <text-highlight :queries="splitSearchKey">
                        {{ $utils.format.text(content.contentText) }}
                      </text-highlight>
                    </s-button>
                    <div class="result-contents" v-else>
                      <text-highlight :queries="splitSearchKey">
                        {{ $utils.format.text(content.contentText) }}
                      </text-highlight>
                    </div>
                  </div>
                  <s-button
                    class="toggle-content-btn"
                    button-type="text"
                    v-if="!$utils.isNormalEmpty(result.showMoreContent)"
                    @click.native="handleToggleMoreContent($event, result)"
                  >
                    {{ toggleMoreContentText(result.showMoreContent) }}
                  </s-button>
                </a>
              </div>
            </div>
          </div>
        </template>
      </div>
      <s-no-data v-else :type="noDataTypeEnum.noResult" />
    </s-loading>
  </div>
</template>

<script>
import TextHighlight from 'vue-text-highlight'
import { ResultToggleMoreLength, noDataTypeEnum } from './global-search.enum'

export default {
  name: 'search-result',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    globalSearchKey: {
      type: String,
      default: '',
    },
    resultGroups: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      noDataTypeEnum,
    }
  },
  components: {
    TextHighlight,
  },
  computed: {
    splitSearchKey() {
      return (this.globalSearchKey || '').split(' ')
    },
  },
  methods: {
    renderAssets(result) {
      return result.showMoreAssets
        ? result.assets
        : this.$lodash.slice(
            result.assets,
            0,
            ResultToggleMoreLength.lineAssets
          )
    },
    toggleMoreAssetsText(result) {
      const hideAssetsCount =
        result.assets.length - ResultToggleMoreLength.lineAssets
      return result.showMoreAssets
        ? this.$t('search_result_show_less')
        : `${hideAssetsCount}+`
    },
    handleToggleMoreAssets(e, result) {
      e.stopPropagation()
      result.showMoreAssets = !result.showMoreAssets
    },
    renderContents(result) {
      return result.showMoreContent
        ? result.resultContents
        : this.$lodash.slice(
            result.resultContents,
            0,
            ResultToggleMoreLength.lineAssets
          )
    },
    toggleMoreContentText(showMoreContent) {
      return showMoreContent
        ? this.$t('search_result_show_less')
        : this.$t('search_result_show_more')
    },
    handleToggleMoreContent(e, result) {
      e.stopPropagation()
      result.showMoreContent = !result.showMoreContent
    },
    handleToggleCollapse(group) {
      group.collapse = !group.collapse
    },
    targetIsCurrentPage(resultRouter) {
      const currentPath = this.$route.path
      const targetPath = resultRouter.split('?')[0]
      return currentPath === targetPath
    },
    linkToTargetRouter(targetRouter) {
      if (
        this.$utils.isEmpty(targetRouter) ||
        this.$route.fullPath === targetRouter
      ) {
        return
      }
      if (this.targetIsCurrentPage(targetRouter)) {
        this.$router.replace(targetRouter)
      } else {
        this.$router.push(targetRouter)
      }
    },
    linkToResultPage(resultRouter) {
      if (resultRouter) {
        this.linkToTargetRouter(resultRouter)
      } else {
        this.$s_toast({
          type: 'error',
          message: this.$t('search_result_no_view_permission_tips'),
          placement: 'topRight',
        })
      }
    },
    linkToContentPage(e, contentRouter) {
      e.stopPropagation()
      this.linkToTargetRouter(contentRouter)
    },
  },
}
</script>

<style lang="scss" scope>
.search-result-container {
  margin-top: 10px;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  max-height: 50vh;
  overflow-y: auto;
  .text__highlight {
    color: #df3030;
    background: transparent;
  }
  .search-result-group-item {
    padding: 0 0 16px;
    &:last-child {
      padding: 0;
    }
  }
  .group-title {
    display: flex;
    align-items: center;
    font-weight: 700;
    color: $font-500;
    .group-toggle-button {
      margin: 0 0 0 8px;
      padding: 0 !important;
      color: $font-400 !important;
      transition: all 0.3s;
      transform: rotate(0deg);
      &.rotate-90 {
        transform: rotate(-90deg);
      }
    }
  }
  .result-list {
    .result-item {
      border: 1px solid $border-200;
      padding: 8px 16px;
      margin: 8px 0px;
      border-radius: 5px;
      a {
        text-decoration: none;
        display: block;
      }
      .result-title {
        font-weight: bold;
        color: $font-500;
        line-height: 24px;
      }
      .result-status {
        font-size: 12px;
        font-weight: 400;
        color: $font-300;
        line-height: 24px;
      }
      .assets-list {
        margin: 8px 0 0;
      }
      .assets-number {
        font-size: 12px;
        color: $font-400;
        margin: 0 8px 8px 0;
      }
      .toggle-assets-btn {
        font-size: 12px;
        color: $font-400;
        height: 24px;
        margin: -8px 0 0;
      }
      .result-contents {
        margin: 4px 0 0;
        height: auto !important;
        font-size: 14px;
        line-height: 22px;
        color: $font-400 !important;
        padding: 0 !important;
        text-align: left !important;
      }
      .toggle-content-btn {
        padding: 0;
        font-size: 14px;
      }
      .none-content-router {
        text-decoration: none;
      }
    }
  }
}
</style>
