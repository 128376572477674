<template>
  <div :class="['app-wrap', { 'dark-background': darkBackground }]">
    <h1 class="top-lever-title" aria-hidden="true">{{ $route.path }}</h1>
    <div class="full-screen-loading" v-if="$store.state.fullScreenLoading">
      <div class="sk-folding-cube">
        <div class="sk-cube1 sk-cube"></div>
        <div class="sk-cube2 sk-cube"></div>
        <div class="sk-cube4 sk-cube"></div>
        <div class="sk-cube3 sk-cube"></div>
      </div>
    </div>

    <router-view></router-view>
    <heartbeat></heartbeat>
  </div>
</template>
<script>
import './plugins/utils/outline.js'
import Heartbeat from './views/components/Heartbeat.vue'

export default {
  name: 'App',
  components: { Heartbeat },
  watch: {
    $route: {
      handler() {
        const title =
          this.documentTitle ||
          this.$ts(this.$route?.meta?.title) ||
          this.$store.state.frontendConfig?.settings?.title
        const titleElement = document.querySelector("title[id='product-title']")
        titleElement.text = title
      },
      immediate: true,
    },
  },
  data() {
    return {
      originalDPR: null,
      originalScreenWidth: null,
    }
  },
  computed: {
    menuList() {
      return this.$store?.state?.userInfo?.roleConfiguration?.menuList || []
    },
    documentTitle() {
      const routePath = this.$route.path
      let documentTitle = ''
      const currentRouteMenu = this.menuList.find(
        (menu) => menu.path === routePath
      )
      documentTitle = currentRouteMenu?.displayName ?? ''
      if (
        !this.$utils.isNormalEmpty(currentRouteMenu?.parentMenuId) &&
        String(currentRouteMenu?.parentMenuId) !== '0'
      ) {
        documentTitle = this.addParentMenuName(currentRouteMenu, documentTitle)
      }
      return documentTitle
    },
    darkBackground() {
      return this.$route.meta?.darkBackground
    },
  },
  created() {
    const vm = this
    let resizeWaiter = false
    this.originalDPR = window.devicePixelRatio
    this.originalScreenWidth = window.screen.width
    this.getZoomOrMobileStatus()
    window.addEventListener('resize', () => {
      if (!resizeWaiter) {
        if (this.originalScreenWidth !== screen.width) {
          this.originalDPR = window.devicePixelRatio
          this.originalScreenWidth = screen.width
        }
        setTimeout(() => {
          vm.getZoomOrMobileStatus()
          resizeWaiter = false
        }, 500)
        resizeWaiter = true
      }
    })
  },
  methods: {
    addParentMenuName(sonMenu, documentTitle) {
      const parentMenu = this.menuList.find(
        (menu) => menu.menuId === sonMenu.parentMenuId
      )
      if (!this.$utils.isNormalEmpty(parentMenu?.displayName)) {
        documentTitle = `${documentTitle} - ${parentMenu.displayName}`
      }
      if (
        !this.$utils.isNormalEmpty(parentMenu?.parentMenuId) &&
        String(parentMenu?.parentMenuId) !== '0'
      ) {
        documentTitle = this.addParentMenuName(parentMenu, documentTitle)
      }
      return documentTitle
    },
    getZoomOrMobileStatus() {
      this.$store.commit(
        'setTwoHundredsTimesZoom',
        window.devicePixelRatio / this.originalDPR >= 2 ||
          window.outerWidth / window.innerWidth >= 2 // for different browsers compatibility
      )
      this.$store.commit(
        'setSysMobilStatus',
        /Mobi|Android|iPhone/i.test(navigator.userAgent)
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.app-wrap {
  min-height: 100vh;
  overflow: hidden;
}

.top-lever-title {
  position: absolute;
  top: -999em;
}
.full-screen-loading {
  background-color: rgba(255, 255, 255, 1);
  .sk-folding-cube .sk-cube:before {
    background-color: $primary;
  }
}
</style>
